import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Router, useRouter } from 'next/router';
import { algoliasearch } from 'algoliasearch';
import { InstantSearch, InstantSearchSSRProvider, InstantSearchServerState } from 'react-instantsearch';
import { AppCategoriesDocument } from '@/generated/contentful';
import { client } from '@/lib/contentful/client';
import { toTitleCase } from '@/utils/string/toTitleCase';
import { getAlgoliaIndex } from './App.utils';
const algoliaClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '', process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '');
export function getAppParentCategoryFromPath(asPath: Router['asPath']) {
  return asPath.split('?')?.at(0)?.split('/categories/')?.[1]?.split('/')?.filter(Boolean)?.at(0) ?? '';
}
function getPageFromQueryParams(query: Router['query']) {
  return typeof query.page === 'string' ? parseInt(query.page) : 1;
}
type Context = {
  parentCategory?: string | null;
  childCategory?: string | null;
};
const Context = createContext<Context>({
  parentCategory: null,
  childCategory: null
});
type Props = {
  children: ReactNode;
  value?: Locale;
  serverState?: InstantSearchServerState;
  parentCategory?: string | null;
  childCategory?: string | null;
};
export function useAppFilters() {
  const {
    query
  } = useRouter();
  const [searchQuery, setSearchQuery] = useState(typeof query.query === 'string' ? query.query : '');
  const [ratingFilter, setRatingFilter] = useState('all');
  const [sortFilter, setSortFilter] = useState('featured');
  const [tierFilter, setTierFilter] = useState('all');
  const [appTypeFilter, setAppTypeFilter] = useState('all');
  const [costPlansFilter, setCostPlansFilter] = useState('all');
  const [compatibleWithFilter, setCompatibleWithFilter] = useState('all');
  const [page, setPage] = useState(getPageFromQueryParams(query));
  useEffect(() => {
    function getQueryParamFromUrl(queryParam: string) {
      const queryParamValue = query[queryParam];
      if (!queryParamValue) return '';
      if (Array.isArray(queryParamValue)) return queryParamValue.join('');
      return queryParamValue;
    }
    const searchQueryParam = getQueryParamFromUrl('query');
    setSearchQuery(searchQueryParam ?? '');
    const sortQueryParam = getQueryParamFromUrl('sort');
    setSortFilter(sortQueryParam || 'featured');
    const ratingQueryParam = getQueryParamFromUrl('min-rating');
    setRatingFilter(ratingQueryParam || 'all');
    const tierQueryParam = getQueryParamFromUrl('tier');
    setTierFilter(tierQueryParam || 'all');
    const appTypeQueryParam = getQueryParamFromUrl('type');
    setAppTypeFilter(appTypeQueryParam || 'all');
    const costPlansQueryParam = getQueryParamFromUrl('costPlans');
    setCostPlansFilter(costPlansQueryParam || 'all');
    const compatibleWithQueryParam = getQueryParamFromUrl('compatibleWith');
    setCompatibleWithFilter(compatibleWithQueryParam || 'all');
    const pageQueryParam = getPageFromQueryParams(query);
    setPage(pageQueryParam);
  }, [query, setSearchQuery, setSortFilter, setRatingFilter, setTierFilter, setAppTypeFilter, setPage, setCostPlansFilter]);
  return {
    searchQuery,
    ratingFilter,
    sortFilter,
    tierFilter,
    appTypeFilter,
    costPlansFilter,
    compatibleWithFilter,
    page
  };
}
let timerId: NodeJS.Timeout | undefined = undefined;
export function useAppCategoryCount() {
  const [totalCount, setTotalCount] = useState(0);
  const [parentCategoriesWithAppCount, setParentCategoriesWithAppCount] = useState<{
    name: string;
    slug: string;
    count: number;
  }[]>([]);
  const [childCategoriesWithAppCount, setChildCategoriesWithAppCount] = useState<{
    parentCategory: {
      name: string;
      slug: string;
    };
    childCategory: {
      name: string;
      slug: string;
    };
    count: number;
  }[]>([]);
  const {
    searchQuery,
    ratingFilter,
    sortFilter,
    tierFilter,
    appTypeFilter,
    costPlansFilter,
    compatibleWithFilter
  } = useAppFilters();
  useEffect(() => {
    function getAppTotalCount() {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(async () => {
        const facetFilters = [];
        //Filters are in this way in order to apply more than 1 filter at the same time
        //Include Tier Filter
        if (tierFilter && tierFilter.toLowerCase() !== 'all') {
          facetFilters.push(`tier.title:${tierFilter}`);
        }
        //Include Type Filter
        if (appTypeFilter && appTypeFilter.toLowerCase() !== 'all') {
          facetFilters.push(`appType:${toTitleCase(appTypeFilter)}`);
        }
        //Filters only for Free Apps
        if (costPlansFilter && costPlansFilter.toLowerCase() === 'free') {
          facetFilters.push('monthlyPrice:0', 'upfrontPrice:0', '-Other');
          //Filters only for paid apps
        } else if (costPlansFilter && costPlansFilter.toLowerCase() === 'paid') {
          facetFilters.push('monthlyPrice:-0');
        } //Filters for multi-storefront
        if (compatibleWithFilter && compatibleWithFilter.toLowerCase() === 'multi-storefront') {
          facetFilters.push('multiStorefront:true');
          //Filter for multiLocation
        } else if (compatibleWithFilter && compatibleWithFilter.toLowerCase() === 'multi-location') {
          facetFilters.push('multiLocation:true');
        } else if (compatibleWithFilter && compatibleWithFilter.toLowerCase() === 'automated-billing') {
          facetFilters.push('automatedBilling:true');
        }
        const algoliaMarketplaceAppsRes = await algoliaClient.search({
          requests: [
          // Gets total count for "All Categories" given the current facets and filters
          {
            query: searchQuery,
            indexName: getAlgoliaIndex(sortFilter),
            hitsPerPage: 0,
            page: 0,
            facets: ['heirarchicalCategories.lvl0', 'heirarchicalCategories.lvl1'],
            sortFacetValuesBy: 'alpha',
            facetFilters: facetFilters.filter(Boolean),
            filters: isNaN(parseInt(ratingFilter)) ? '' : `rating >= ${ratingFilter}`
          }]
        });
        const parentCategoriesWithAppCount = Object.entries(algoliaMarketplaceAppsRes?.results?.[0]?.facets?.['heirarchicalCategories.lvl0'] ?? {})?.map(([slug, count]) => ({
          slug,
          count
        }));
        const childCategoriesWithAppCount = Object.entries(algoliaMarketplaceAppsRes?.results?.[0]?.facets?.['heirarchicalCategories.lvl1'] ?? {})?.map(([category, count]) => {
          const [parentCategory, childCategory] = category.split(' > ');
          return {
            parentCategory,
            childCategory,
            count
          };
        });
        async function getAppCategories() {
          const {
            categoryCollection
          } = await client().request(AppCategoriesDocument, {
            where: {
              slug_in: [...parentCategoriesWithAppCount.map(({
                slug
              }) => slug), ...childCategoriesWithAppCount.map(({
                childCategory
              }) => childCategory)]
            }
          });
          setParentCategoriesWithAppCount(parentCategoriesWithAppCount.map(({
            slug,
            count
          }) => ({
            name: categoryCollection?.items?.find(category => category?.slug === slug)?.name ?? '',
            slug,
            count
          })));
          setChildCategoriesWithAppCount(childCategoriesWithAppCount.map(({
            parentCategory,
            childCategory,
            count
          }) => ({
            parentCategory: {
              name: categoryCollection?.items?.find(category => category?.slug === parentCategory)?.name ?? '',
              slug: parentCategory
            },
            childCategory: {
              name: categoryCollection?.items?.find(category => category?.slug === childCategory)?.name ?? '',
              slug: childCategory
            },
            count
          })));
          setTotalCount(algoliaMarketplaceAppsRes.results[0].nbHits);
        }
        getAppCategories();
      }, 300);
    }
    getAppTotalCount();
  }, [searchQuery, ratingFilter, sortFilter, tierFilter, appTypeFilter, costPlansFilter, compatibleWithFilter]);
  return {
    totalCount,
    parentCategoriesWithAppCount,
    childCategoriesWithAppCount
  };
}
export function AppsProvider({
  serverState,
  parentCategory,
  childCategory,
  children
}: Props) {
  return <InstantSearchSSRProvider {...serverState} data-sentry-element="InstantSearchSSRProvider" data-sentry-component="AppsProvider" data-sentry-source-file="AppsProvider.tsx">
			<InstantSearch searchClient={(algoliaClient as any)} indexName={process.env.NEXT_PUBLIC_ALGOLIA_MARKETPLACE_APPS_INDEX} data-sentry-element="InstantSearch" data-sentry-source-file="AppsProvider.tsx">
				<Context.Provider value={{
        parentCategory,
        childCategory
      }} data-sentry-element="unknown" data-sentry-source-file="AppsProvider.tsx">
					{children}
				</Context.Provider>
			</InstantSearch>
		</InstantSearchSSRProvider>;
}
export function useAppsCurrentcategories() {
  const {
    parentCategory,
    childCategory
  } = useContext(Context);
  return {
    parentCategory: parentCategory ? {
      title: toTitleCase(parentCategory ?? ''),
      slug: parentCategory
    } : null,
    childCategory: childCategory ? {
      title: toTitleCase(childCategory ?? ''),
      slug: childCategory
    } : null
  };
}