import { MarketplaceAppOrder } from '@/generated/contentful'

export type AppType = 'featured' | 'recommended' | 'top-free' | 'top-paid' | 'newest'

export const ALGOLIA_APPS_HITS_PER_PAGE = 12

export function getAppParams(type: AppType) {
	switch (type) {
		case 'featured': {
			return {
				where: { featured: true },
				order: MarketplaceAppOrder.FeaturedOrderDesc
			}
		}
		case 'recommended': {
			return {
				where: { recommended: true },
				order: MarketplaceAppOrder.RecommendedOrderDesc
			}
		}

		case 'top-free': {
			return {
				where: { pricingType: 'Free' },
				order: MarketplaceAppOrder.RankDesc
			}
		}

		case 'top-paid': {
			return {
				where: { pricingType_not: 'Free' },
				order: MarketplaceAppOrder.RankDesc
			}
		}

		case 'newest': {
			return {
				order: MarketplaceAppOrder.ApprovedAtDesc
			}
		}
	}
}

export function getAlgoliaIndex(sortBy: string) {
	switch (sortBy?.toLowerCase()) {
		case 'popular':
			return process.env.NEXT_PUBLIC_ALGOLIA_MARKETPLACE_APPS_INDEX_POPULAR || ''

		case 'newest':
			return process.env.NEXT_PUBLIC_ALGOLIA_MARKETPLACE_APPS_INDEX_NEWEST || ''

		default:
			return process.env.NEXT_PUBLIC_ALGOLIA_MARKETPLACE_APPS_INDEX || ''
	}
}
